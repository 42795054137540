.icon {
  margin-right: 0.25rem;
  animation: rotation 1.5s infinite;
  stroke-width: 2px;
  color: var(--primary);
}

@keyframes rotation {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 359deg;
  }
}
