.icon {
    animation: rotation 1s infinite linear;
    margin-right: 0.75rem;
    color: var(--primary);
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  
  .mainDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  