.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mt-none {
  margin-top: 0 !important;
}

.mb-none {
  margin-bottom: 0 !important;
}

.m-none {
  margin: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.pt-none {
  padding-top: 0 !important;
}

.pb-none {
  padding-bottom: 0 !important;
}

.p-none {
  padding: 0 !important;
}
