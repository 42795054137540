.aside.aside-dark .aside-logo {
  background-color: #010304 !important;
}

:root {
  --primary: #000;
  --primary-active: #494949;
  --primary-light: #f1faff;
  --primary-light-dark: #212e48;
  --primary-inverse: #ffffff;
}

.bg-client,
.aside.aside-dark {
  background-color: #131313 !important;
}

.aside-dark
  .menu
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled):not(.active):not(.here),
.aside-dark
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here),
.aside-dark .menu .menu-item.show > .menu-link {
  background-color: #0d1518 !important;
}

.aside-dark .menu .menu-item.show > .menu-link .menu-title {
  color: #fff !important;
  font-weight: 500 !important;
}

.nav-line-tabs .nav-item .nav-link.active,
.btn.btn-active-color-custom:hover:not(.btn-active) i,
.btn.btn-active-color-custom:hover:not(.btn-active) {
  color: #131313 !important;
}

.aside-dark .menu .menu-item.here > .menu-link {
  background-color: #0d1518 !important;
}

.aside-dark .menu .menu-item .menu-link .menu-title {
  color: #a0a0a1;
}

.aside-dark .menu .menu-item.show > .menu-link .menu-icon,
.aside-dark .menu .menu-item.show > .menu-link .menu-icon .svg-icon,
.aside-dark .menu .menu-item.show > .menu-link .menu-icon i {
  color: red !important;
}

.form-check-input {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border: 1px solid #a2a2a2 !important;
}

.form-check-input.male:checked {
  background-color: #0090c9d4 !important;
  border-color: #0090c9d4 !important;
}

.form-check-input.female:checked {
  background-color: #ec9eab !important;
  border-color: #ec9eab !important;
}

.form-check-input.green:checked {
  background-color: #009c0c !important;
  border-color: #009c0c !important;
}

.form-check-input.red:checked {
  background-color: #f20c16 !important;
  border-color: #f20c16 !important;
}

.bg-male {
  background-color: #0090c9d4 !important;
}

.bg-female {
  background-color: #ec9eab !important;
}

.col-form-label {
  font-weight: 500 !important;
  padding-top: 0px !important;
  padding-bottom: 0.25rem !important;
  color: #000 !important;
}

.width-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.bordered {
  border-radius: 2px !important;
}

div.bg-success,
.width-fit > .bg-success,
.label.bg-success {
  background-color: #0da00d !important;
}

.width-fit > .bg-warning,
.label.bg-warning {
  background-color: #d5a103 !important;
}

.dataTable thead tr th,
.table-custom thead tr th {
  font-weight: 600 !important;
  font-size: 13px !important;
}

.dataTable tbody tr td {
  font-size: 13px;
  font-weight: 400;
}

.table > :not(caption) > * > * {
  padding: 0.45rem 0.55rem !important;
  border-bottom-width: 0.5px !important;
}

table.dataTable.table-hover > tbody > tr:not(.active):hover {
  background-color: #f6f6f6 !important;
}

.table td:first-child,
.table th:first-child,
.table tr:first-child {
  padding-left: 10px !important;
}

@media (min-width: 992px) {
  .aside .aside-logo {
    height: 120px !important;
  }

  .aside-enabled.aside-fixed .wrapper {
    padding-left: 285px !important;
  }

  .aside {
    width: 285px !important;
  }

  .aside-enabled.aside-fixed.toolbar-fixed .toolbar,
  .aside-enabled.aside-fixed.header-fixed .header {
    left: 285px !important;
  }
}

#kt_aside_logo a {
  margin: 0 auto;
}

.aside-menu .menu .menu-item .menu-link {
  padding-right: 5px !important;
}

a.nav-link.pointer.active {
  color: #000000 !important;
  font-weight: 600;
}

.alert-success {
  color: #188148 !important;
  border-color: #188148 !important;
}

.alert-info {
  color: #3e8eb0 !important;
  border-color: #3e8eb0 !important;
}

.tooltip {
  z-index: 9999 !important;
}

.accordion-button {
  padding: 10px 10px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: 600 !important;
  color: #000 !important;
}

.b-dashed {
  border-bottom: 1px dashed #d5d5d5;
}

.symbol-photo .symbol-label {
  width: 110px !important;
  height: 120px !important;
}

.symbol.symbol-120px .symbol-label {
  width: 120px;
  height: 120px;
}

.well-outline {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #efefef;
}

.border-bottom-tabela {
  border-bottom: 1px solid #efefef !important;
}

.mt-05 {
  margin-top: 0.17rem !important;
}

.xsmall {
  font-size: 0.785em;
}

.d-none {
  display: none;
}

.btn-sm {
  padding: calc(0.1rem + 1px) calc(0.25rem + 1px) !important;
}
/************************************/

.icon-label {
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.btn-slim {
  padding: 2.5px 10px 2.5px 10px !important;
  font-size: 12px !important;
  height: 30px;
}

.bg-m {
  padding: 10px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  background-color: #0090c9d4 !important;
}

.bg-fm {
  padding: 10px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  background-color: #ec9eab !important;
}

.hover:hover {
  color: red;
  transition-duration: 0.5s;
}

.menu-item .menu-link {
  font-weight: 500;
}

.card .card-header {
  border-bottom: 0px !important;
}

.card-body {
  padding: 0 2.25rem 2.25rem !important;
}

.nav-line-tabs .nav-item .nav-link {
  font-weight: 500 !important;
}

.label-bs,
.bg-bs {
  background-color: #ed1c24 !important;
  color: #fff !important;
}

.cor-bs {
  color: #ed1c24;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 0.8rem;
  padding: 0.3rem 0.4rem;
  border-radius: 5px;
  color: white;
}

.label.label-rounded {
  border-radius: 1rem;
}

.alert-danger {
  color: #912741 !important;
  background-color: #fcd9e2 !important;
  border-color: #fbc6d3 !important;
}

.f-r {
  float: right;
}

.form-control {
  font-size: 1rem !important;
  font-weight: 400 !important;
  border: 1px solid #e0e0e0 !important;
  color: black !important;
  padding: 8px !important;
  border-radius: 4px !important;
}

.form-control:hover {
  border-color: #cccccc !important;
}
.form-control:focus {
  border-color: white !important;
  outline: 1px solid #a6a6a6 !important;
}

.form-select {
  font-size: 1rem !important;
  font-weight: 400 !important;
  border: 1px solid #e0e0e0 !important;
  color: black !important;
  border-radius: 4px !important;
  padding: 8px 35px 8px 8px !important;
  background-color: white !important;
}

::placeholder {
  color: #8e8e8e !important;
  opacity: 100% !important;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: 0;
}

.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  box-shadow: none !important;
}

.btn-group-sm > .btn.btn-icon,
.btn.btn-icon.btn-sm {
  height: calc(1.5em + 1.1rem + 2px);
  width: calc(1.5em + 1.1rem + 2px);
}

.btn.btn-light {
  color: #7e8299;
  border-color: #f5f8fa;
  background-color: #f5f8fa;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem + 2px);
  width: calc(1.5em + 1.5rem + 2px);
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.55rem 0.75rem !important;
  font-size: 0.925rem !important;
  line-height: 1.35;
  border-radius: 0.42rem;
}

.breadcrumb .breadcrumb-item {
  font-weight: 400;
}

.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 0.75rem !important;
}
.mr-4 {
  margin-right: 1rem !important;
}
.mr-5 {
  margin-right: 1.25rem !important;
}
.mr-6 {
  margin-right: 1.5rem !important;
}
.mr-7 {
  margin-right: 1.75rem !important;
}
.mr-8 {
  margin-right: 2rem !important;
}
.mr-9 {
  margin-right: 2.25rem !important;
}
.mr-10 {
  margin-right: 2.5rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 0.75rem !important;
}
.ml-4 {
  margin-left: 1rem !important;
}
.ml-5 {
  margin-left: 1.25rem !important;
}
.ml-6 {
  margin-left: 1.5rem !important;
}
.ml-7 {
  margin-left: 1.75rem !important;
}
.ml-8 {
  margin-left: 2rem !important;
}
.ml-9 {
  margin-left: 2.25rem !important;
}
.ml-10 {
  margin-left: 2.5rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pr-3 {
  padding-right: 0.75rem !important;
}
.pr-4 {
  padding-right: 1rem !important;
}
.pr-5 {
  padding-right: 1.25rem !important;
}
.pr-6 {
  padding-right: 1.5rem !important;
}
.pr-7 {
  padding-right: 1.75rem !important;
}
.pr-8 {
  padding-right: 2rem !important;
}
.pr-9 {
  padding-right: 2.25rem !important;
}
.pr-10 {
  padding-right: 2.5rem !important;
}
.pl-0 {
  padding-left: 0rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pl-3 {
  padding-left: 0.75rem !important;
}
.pl-4 {
  padding-left: 1rem !important;
}
.pl-5 {
  padding-left: 1.25rem !important;
}
.pl-6 {
  padding-left: 1.5rem !important;
}
.pl-7 {
  padding-left: 1.75rem !important;
}
.pl-8 {
  padding-left: 2rem !important;
}
.pl-9 {
  padding-left: 2.25rem !important;
}
.pl-10 {
  padding-left: 2.5rem !important;
}

.btn {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.card .card-header .card-title,
.card .card-header .card-title .card-label {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.Toastify__toast-body {
  font-size: 14px !important;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: initial !important;
  }
}

.card {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.card .card-footer {
  border: 0px !important;
}

.filters {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.radioDiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.radioDiv > div {
  padding-right: 15px;
}

.form-check {
  margin-right: 1rem !important;
}

.card-border {
  border: 1px solid #f2f5f7 !important;
}

.bt-0 {
  border-top: 0px !important;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.bg-lighgray {
  background: #e8e8e8 !important;
}

.bg-gray {
  background: #f2f6f8 !important;
}
.bg-red {
  background: #ff2626 !important;
  color: white;
}

.col-form-text {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase !important;
}

.fs-12px {
  font-size: 12px;
}

.alert.bg-light-danger {
  background-color: #ffe2e5 !important;
  border-color: transparent !important;
}

.width-fit {
  width: fit-content;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-bolder {
  font-weight: bolder;
}

.label.label-sm {
  height: 16px;
  font-size: 0.75rem;
}

.btn-gray {
  background: #e5e5e5 !important;
}

.btn-gray:hover {
  background: #cdcdcd !important;
}

.bg-secondary.rounded {
  background: #efefef !important;
}

.dropdown-item {
  padding: 5px 5px 10px 10px !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bs-dropdown-link-hover-bg) !important;
}

.min-24px {
  min-width: 24px;
}

#root-select {
  z-index: 2000 !important;
}

.grow {
  flex-grow: 1 !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-align-center {
  text-align: center !important;
}

.label-notification {
  position: relative;
  top: -8px;
  right: 10px;
  width: 10px;
  height: 10px;
  padding: 4px !important;
  background-color: #f04632;
}

.cards-apps.card-app-container {
  border-radius: 20px;
  border: 1px solid #dfdfdf !important;
  height: 220px;
}

.card.card-app-container.cards-apps:not(.no-hover):hover {
  border-color: #1e1e1e !important;
  cursor: pointer;
}

.card.card-app-container.cards-apps.no-hover:hover {
  cursor: initial !important;
}

.cards-apps {
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding: 40px 10px 40px 10px;
  gap: 12px;
}

.cards-apps .texto {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.015em;
  color: #1e1e1e;
}

.cards-apps .icon {
  border-radius: 15%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
}

.icon {
  animation: rotation 1s infinite linear;
  margin-right: 0.75rem;
  color: var(--primary-inverse);
}

.bold {
  font-weight: bold !important;
}

.inherit {
  height: inherit !important;
}

.underlined {
  text-decoration: underline !important;
}

.popover {
  z-index: 9999;
}
